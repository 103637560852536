<template>
    <div class="image-upload">
        <el-upload
            class="upload-demo ecert_upload"
            action="#"
            accept="image/jpeg,image/jpg,image/png"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :on-preview="handlePreview"
            :limit="1"
            :file-list="fileList"
            :auto-upload="false"
        >
            <el-button size="small" round>{{ typeof(btnText) === 'string' ? btnText : btnText.text }}</el-button>
            <template #tip>
                <div class="el-upload__tip">{{ $t('data.public.best') }} PNG, SVG</div>
            </template>
        </el-upload>
        <el-dialog v-model="picturePopup" width="50%" center>
            <vue-picture-cropper
                :boxStyle="{
                width: '100%',
                height: '100%',
                backgroundColor: '#f8f8f8',
                margin: 'auto'
                }"
                :img="pic"
                :options="{
                    viewMode: 1,
                    dragMode: 'none',
                    zoomable: false,
                    aspectRatio: 1.665,
                    cropBoxResizable: true
                }"
                :presetMode="{
                    mode: 'fixedSize',
                    width: 293,
                    height: 176,
                }"
            />
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="picturePopup = false">{{ $t('data.public.cancel') }}</el-button>
                    <el-button type="primary" @click="pictureConfirm">{{ $t('data.public.confirm') }}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {ref} from 'vue'
import { uploadImage } from '../../api/index'
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
export default {
  name: 'ImageUpload',
  props: {
      btnText: String | Object
  },
  data(){
    return{
        imageFile: {
            text: this.btnText,
            file: {},
            url: ''
        },
        fileList: [],
        picturePopup: false,
        pic: ''
    }
  },
//   setup(){
//     let fileList = ref([]);
//     return {
//         fileList
//     }
//   },
  mounted(){
      if(this.btnText.url){
          this.fileList = [{name: this.btnText.url, url: this.btnText.url}]
      }
  },
  components: {
    VuePictureCropper
  },
  computed: {
  },
  methods:{
      
      handleRemove(file, fileList) {
        this.imageFile.url = "";
        this.imageFile.file = {};
        this.$emit('imageFile', this.imageFile);
        return;
      },

      handleChange(file, fileList){
        // this.getBase64(file.raw).then(res=>{
        //     this.imageFile.url = res;
        // });
        if(this.btnText.text === 'Upload Picture 1' || this.btnText.text === 'Upload Picture 2' || this.btnText.text === 'Upload Picture 3'){
            let URL = window.URL || window.webkitURL;
            this.pic = URL.createObjectURL(file.raw);
            this.picturePopup = true;
        } else {
            let param = new FormData();
            param.append('image', file.raw)
            param.append('type', 'logo')
            let apiData = param
            uploadImage({apiData}).then( res => {
                this.imageFile.url = res.data.url;
                this.imageFile.file = file;
                this.$emit('imageFile', this.imageFile)
            })
        }
      },
      handlePreview(file){
          if(file.url){
              window.open(file.url)
          } else {
              let previewUrl;
              let URL = window.URL || window.webkitURL;
              previewUrl = URL.createObjectURL(file.raw);
              window.open(previewUrl);
          }
      },
      getBase64(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            let fileResult = "";
            reader.readAsDataURL(file);
            reader.onload = function() {
            fileResult = reader.result;
            };
            reader.onerror = function(error) {
            reject(error);
            };
            reader.onloadend = function() {
            resolve(fileResult);
            };
        });
      },
      pictureConfirm(){
        cropper.getFile().then((file) => {
            let param = new FormData();
            param.append('image', file)
            param.append('type', 'logo')
            let apiData = param
            uploadImage({apiData}).then( res => {
                this.imageFile.url = res.data.url;
                this.imageFile.file = file;
                this.$emit('imageFile', this.imageFile)
                this.picturePopup = false;
            })
        })
      }

  }
}
</script>

<style scoped>
.image-upload{
    margin: 10px 0px;
}
.ecert_upload{
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
}
.ecert_upload .el-upload__tip{
    margin-top: 0px;
}
</style>
<style>
.el-upload-list__item .el-icon-close-tip{
    display: none !important;
}
.image-upload .el-upload-list__item{
    margin-top: 0px !important;
}
.ecert_upload .el-upload-list--text{
    width: 30%;
}
.ecert_upload .el-upload{
    width: 40%;
    text-align: left;
}
.ecert_upload .el-upload__tip{
    width: 30%;
}
</style>
